const locales = [
  {
    locale: 'us',
    city: 'New York',
    phone: '+1 (646) 849-6260',
    email: 'contact@hiredigital.com',
  },
  {
    locale: 'sg',
    city: 'Singapore',
    phone: '+65 6910 8567',
    email: 'contact@hiredigital.com',
  },
  {
    locale: 'none',
    city: '',
    phone: '',
    email: 'contact@hiredigital.com',
  },
];

export { locales };
