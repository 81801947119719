import { memo } from 'react';
import Styles from './Footer.module.scss';
import PropTypes from 'prop-types';

const FooterNav = memo(({ className, title, children }) => {
  return (
    <div className={className}>
      <h4 className={Styles.navTitle}>{title}</h4>
      <ul className={Styles.navItem}>{children}</ul>
    </div>
  );
});
FooterNav.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default FooterNav;
