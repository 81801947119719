import Link from '@components/Link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './ListLink.module.scss';

const ListLink = (props) => {
  let attrs;

  if (props.external) {
    attrs = {
      target: '_blank',
      rel: 'noopener',
    };
  }
  const linkTitle = props.title ? props.title : '';

  const colorClass = props.type || Styles.darkgray; // default to darkgray if no type is supplied

  return (
    <li className={classNames(Styles.list, props.className)} id={props.id}>
      {/^https?:\/\//.test(props.to) || props.contact ? (
        <a
          className={classNames(Styles.anchor, colorClass)}
          href={props.to}
          {...attrs}
          title={linkTitle}>
          {props.children}
        </a>
      ) : (
        <Link className={classNames(Styles.anchor, colorClass)} to={props.to} title={linkTitle}>
          {props.children}
        </Link>
      )}
    </li>
  );
};

ListLink.Type = {
  DARKBLUE: Styles.darkblue,
  GRAY: Styles.gray,
  DARKGRAY: Styles.darkgray,
  WHITE: Styles.white,
  BLACK_BOLD: Styles.blackBold,
};

ListLink.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  external: PropTypes.bool,
  to: PropTypes.string,
  contact: PropTypes.bool,
  children: PropTypes.node,
};

export default ListLink;
